import { ComponentContainer } from '@genially/public-web-components';
import type { FC } from 'react';
import MegamenuContent from '../MegamenuContent';
import WithLayout from '../WithLayout';

interface DataProps {
  content: any;
}

const Megamenu: FC<DataProps> = ({ content }) => {
  return (
    <main role="main" data-node-id={content.id} about={content.url?.path}>
      <ComponentContainer
        style={{
          paddingTop: '50px !important',
          maxWidth: '1200px',
          margin: 'auto',
        }}
      >
        <h2 style={{ marginBottom: '20px' }}>
          This page is only build for preview/testing purposes
        </h2>
        <div
          style={{
            boxShadow: '0px 12px 32px 0px rgba(108, 41, 255, 0.15)',
            background: 'white',
            maxWidth: '1014px',
          }}
        >
          <MegamenuContent content={content} />
        </div>
      </ComponentContainer>
    </main>
  );
};

export default WithLayout(Megamenu);
